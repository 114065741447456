
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLogged } from '../helpers/userlogged';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    userData: Observable<firebase.User>;
    public router: Router;
    constructor(private angularFireAuth: AngularFireAuth, router: Router) {
        this.userData = angularFireAuth.authState;
        this.router = router;
    }

    /* Sign up */
    // SignUp(email: any, password: any) {
    // this.angularFireAuth
    // .auth
    // .createUserWithEmailAndPassword(email, password)
    // .then(res => {
    // console.log('You are Successfully signed up!', res);
    // })
    // .catch(error => {
    // console.log('Something is wrong:', error.message);
    // });
    // }

    /* Sign in */
    SignIn(email: any, password: any) {
        debugger;
        this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
            .then(res => {
                console.log(res)
                let getIdToken =  this.angularFireAuth.auth.currentUser.getIdToken();
                // console.log(this.angularFireAuth.auth.currentUser.getIdToken())
                let idtoken = ''
                // getIdToken.then(
                //     (result: any) =>  {
                //         idtoken = result
                //         console.log(result)
                //         let userLogged: UserLogged = new UserLogged();
                //         userLogged.setCurrentUser(idtoken, res.user.email, res.user.uid, null);
                //         this.router.navigate(['/dashboard']);
                //         console.log('You are Successfully logged in!');
                //     }
                //  )
            })
            .catch(err => {
                console.log('Something is wrong:', err.message);
            });
    }

    /* Sign out */
    SignOut() {
        this.angularFireAuth
            .auth
            .signOut();
    }

}