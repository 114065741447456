import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UserLogged } from 'src/app/core/helpers/userlogged';
import { ODataResponse } from 'src/models/database/odata-response.model';
import { TblContactService } from 'src/services/tblContact.service';
import { TblWorkerService } from 'src/services/tblWorker.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  public router: Router;
  username: string;
  userId: any;
  contact : any;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute, private tblWorkerService: TblWorkerService , private tblContactService: TblContactService ) {
    this.router = router;
  }


  ngOnInit() {
    let userLogged: UserLogged = new UserLogged();
    if (userLogged.isLogged()) {
      // this.userLogin = true;
      let user = userLogged.getCurrentUser();
      // this.userId = user.userId;
      this.userId = user.userId;
      let getContact = this.tblContactService.getContactById(this.userId).pipe(
        finalize(() => {
            getContact.unsubscribe();
        })
    ).subscribe(
        (res: ODataResponse) => {
            this.contact = res;
        },
        (err) => {
            console.log(err);
        });
    }
  };

  logOut() {
    let userLogged: UserLogged = new UserLogged();
    userLogged.logout();
    this.router.navigate(['/login-remittance']);
    // this.username = '';
    return false;
  }

}
