import { Constants } from '../constants/constants';
import { FormGroup, FormControl, FormArray, AbstractControl, NgControl } from '@angular/forms';
import { QueryExpression } from '../models/query-expression.model';
import { Cell } from 'ng2-smart-table';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';
import { maskInput } from 'vanilla-text-mask';
import { MessageContants } from '../constants/message.constants';
import { isNgbDatepickerValue, pad } from './functions';
import { JsonConvert } from 'json2typescript';
import { Column } from 'ng2-smart-table/lib/lib/data-set/column';
declare var $: any;
export class Utils {
    public static isFileImage(fileName: string): boolean {
        if (fileName.match(/.(JPG|JPEG|PNG|ICO|GIF|WEBP|TIFF|BMP)$/i)) {
            return true;
        } else {
            return false;
        }
    }
    public static isFilePdf(fileName: string): boolean {
        if (fileName.match(/.(pdf)$/i)) {
            return true;
        } else {
            return false;
        }
    }
    public static isFileWord(fileName: string): boolean {
        if (fileName.match(/.(doc|docx)$/i)) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * Get filename from url
     * @param url
     */
    public static getFileNameFromUrl(url: string): string {
        if (url) {
            const filename = url.substring(url.lastIndexOf('/') + 1);
            return filename;
        }
        return '';
    }
    /**
     * Get value from array by index, if not found return null
     * @param arrayValues
     * @param index
     */
    public static getArrayValueByIndex(arrayValues: any[], index: number) {
        if (arrayValues) {
            for (let i = 0; i < arrayValues.length; i++) {
                if (i === index) {
                    return arrayValues[i];
                }
            }
        }
        return null;
    }

    public static getErrorMessage(err: any): string {
        let error = MessageContants.UNKNOWN_ERROR_OCCURRED;
        if (err.error && err.error.error && err.error.error.message) {
            error = err.error.error.message;
        } else if (err.statusText) {
            error = err.statusText;
        }
        return error;
    }
    public static getRootPath() {
        const href = location.href;
        if (href.indexOf('/#/') !== -1) {
            return location.pathname + '#';
        } else {
            return location.pathname;
        }
    }
    public static getDateUTCNow(): number {
        const date = new Date();
        const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return now_utc;
    }

    // public static getDateUTCFromBootstrapDatepicker(value): Date {
    //     if (value) {
    //         return new Date(Date.UTC(value.year, value.month - 1, value.day));
    //     } else {
    //         return null;
    //     }
    // }

    public static getDateFromBootstrapDatepicker(value): Date {
        if (isNgbDatepickerValue(value)) {
            return new Date(value.year, value.month - 1, value.day);
        } else {
            return null;
        }
    }

    /**
     * Validate all form fields to make sure data to be entered correct
     * @param formGroup
     */
    public static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                this.checkToShowValidatorErrorMessage(field, control);
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (let index = 0; index < control.controls.length; index++) {
                    const form = control.controls[index];
                    Object.keys(form['controls']).forEach(field => {
                        const childControl: FormControl = form['controls'][field];
                        childControl.markAsTouched({ onlySelf: true });
                        this.checkToShowValidatorErrorMessage(field, control);
                    });
                }
            }
        });
    }

    public static checkToShowValidatorErrorMessage(formControlName: string, control: any): boolean {
        let hasError = false;
        var errorClass = formControlName + "_error";
        $("." + errorClass).remove();
        let errors = control.errors;
        if (errors) {
            hasError = true;
            delete errors.ngbDate; //need to delete this to fix issue show dupplicate error
            var $el = $(`[formControlName=${formControlName}]`);
            if ($el.length == 0) {
                $el = $(`#${formControlName}`);
            }
            for (const [key, v] of Object.entries(errors)) {
                const attr = $($el).attr('ngbDatepicker');
                let isDateTimePicker = false;
                if ($el && $el[0] && $el[0].localName) {
                    isDateTimePicker = $el[0].localName == 'app-date-time-picker';
                }
                if ((typeof attr !== 'undefined' && attr !== false) || isDateTimePicker) {
                    if (isDateTimePicker) {
                        if (key == "required" && v) {
                            $el.parent().append("<small class='text-danger " + errorClass + "'>Dữ liệu không được để trống</small>");
                        } else if (errors.datetimeInPast) {
                            $el.parent().append("<small class='text-danger " + errorClass + "'>Thời gian phải nằm trong tương lai</small>");
                        } else if (errors.isValid) {
                            $el.parent().append("<small class='text-danger " + errorClass + "'>Dữ liệu không đúng định dạng (vd: 30/12/2021)</small>");
                        } else {
                            $el.parent().append("<small class='text-danger " + errorClass + "'>" + v + "</small>");
                        }
                    }
                    else {
                        if (key == "required" && v) {
                            $el.parent().after("<small class='text-danger " + errorClass + "'>Dữ liệu không được để trống</small>");
                        } else if (errors.datetimeInPast) {
                            $el.parent().after("<small class='text-danger " + errorClass + "'>Thời gian phải nằm trong tương lai</small>");
                        } else if (errors.isValid) {
                            $el.parent().after("<small class='text-danger " + errorClass + "'>Dữ liệu không đúng định dạng (vd: 30/12/2021)</small>");
                        } else {
                            $el.parent().after("<small class='text-danger " + errorClass + "'>" + v + "</small>");
                        }
                    }
                } else {
                    var $el = null;
                    //for special case, we need to show error to correct loation
                    var $elTemp1 = $(`.${formControlName}Errors`);
                    if ($elTemp1.length > 0) {
                        $el = $elTemp1;
                    }
                    else {
                        var $elTemp2 = $(`[formControlName=${formControlName}]`);
                        if ($elTemp2.length == 0) {
                            $elTemp2 = $(`#${formControlName}`);
                        }
                        $el = $elTemp2.parent();
                    }
                    if (key == "required" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Dữ liệu không được để trống</small>");
                    } else if (key == "equalTo" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Mật khẩu không trùng khớp</small>");
                    } else if (key == "email" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Email không đúng định dạng</small>");
                    } else if (key == "emailTaken" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Email này đã tồn tại</small>");
                    } else if (key == "codeTaken" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Mã khách hàng này đã tồn tại</small>");
                    } else if (key == "phoneTaken" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Số điện thoại này đã tồn tại</small>");
                    } else if (key == "advNoTaken" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>An vehicle with this Advertising No already exists</small>");
                    } else if (key == "url" && v) {
                        $el.append("<small class='text-danger " + errorClass + "'>Địa chỉ Website không đúng định dạng (vd: https://google.com.vn/)</small>");
                    } else if (key == "pattern" && v["requiredPattern"] == "^[0-9]*$") {
                        $el.append("<small class='text-danger " + errorClass + "'>Chỉ được phép nhập số");
                    } else {
                        $el.append("<small class='text-danger " + errorClass + "'>" + v + "</small>");
                    }
                }
            }
            return hasError;
        }
    }

    /**
     * Disable all controls in form
     * @param formGroup
     */
    public static disableAllFormFields(formGroup: FormGroup) {
        if (formGroup && formGroup.controls) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);
                if (control instanceof FormControl) {
                    control.disable();
                }
            });
        }
    }

    /**
     * Enable all controls in form
     * @param formGroup
     */
    public static enableAllFormFields(formGroup: FormGroup) {
        if (formGroup && formGroup.controls) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);
                if (control instanceof FormControl) {
                    control.enable();
                }
            });
        }
    }

    // create an ISOString date from day, month, year
    public static formatDateTimeToFilterAndPostData(day: number, month: number, year: number) {
        return new Date(year, month, day).toISOString();
    }

    // format date dd/mm/yyyy
    public static formatDate(date: Date) {
        return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }
    /**
     * This function is used to check a value to be number or not
     * @param n value to check
     */
    public static isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    public static isNullOrUndefined(value: any): boolean {
        if (typeof value === 'undefined' || value === null) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * Check object is empty or not
     * @param obj
     */
    public static isObjectEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    public static extractStartDateEndDateFromSearch(search: string): any {
        if (search) {
            const temp = search.split('đến');
            if (!temp || temp.length === 1) {
                return {
                    startDate: null,
                    endDate: null
                };
            }
            const temp1 = temp[0].replace(new RegExp('_', 'g'), '').trim().split('/');
            const temp2 = temp[1].replace(new RegExp('_', 'g'), '').trim().split('/');
            const d1 = temp1[0];
            const m1 = temp1[1];
            const y1 = temp1[2];
            const d2 = temp2[0];
            const m2 = temp2[1];
            const y2 = temp2[2];
            let startDate = null;
            try {
                if (this.isNumeric(y1) && y1.length === 4 && this.isNumeric(m1) && this.isNumeric(d1)) {
                    startDate = this.toJSONFormat(new Date(Number(y1), Number(m1) - 1, Number(d1) - 1));
                }
            } catch (err) {
                console.log(err);
            }

            let endDate = null;
            try {
                if (this.isNumeric(y2) && y2.length === 4 && this.isNumeric(m2) && this.isNumeric(d2)) {
                    endDate = this.toJSONFormat(new Date(Number(y2), Number(m2) - 1, Number(d2)));
                }
            } catch (err) {
                console.log(err);
            }

            return {
                startDate: startDate,
                endDate: endDate
            };
        } else {
            return {
                startDate: null,
                endDate: null
            };
        }
    }
    public static toJSONFormat(date: Date): string {
        function pad(number) {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        }
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
            'Z';
    }
    public static getByteArrays(base64): any {
        const sliceSize = 1024;
        const byteCharacters = atob(base64);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
    }
    /**
     * This function is remove the Vietnamese Mark
     * @param str
     */
    public static removeTheVietnameseMark(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        return str;
    }

    /**
     * Remove special characters (not letters and numbers)
     * @param str
     */
    public static removeSpecialCharacters(str) {
        str = str.replace(/[^0-9a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s]/gi, '');
        return str;
    }

    /**
     * The function supports to buid odata query
     * @param pathOrqueryExpression This is full odata query or QueryExpression
     * @returns returns a string odata query
     */
    public static buidODataQuery(pathOrqueryExpression: string | QueryExpression): string {
        let odataQuery = '';
        if (typeof pathOrqueryExpression === 'string') {
            odataQuery = pathOrqueryExpression;
        } else {
            if (pathOrqueryExpression.EntitySet) {
                odataQuery = `${pathOrqueryExpression.EntitySet}?`;
            } else {
                odataQuery = '?';
            }

            // $count
            if (pathOrqueryExpression.IsCount) {
                odataQuery = `${odataQuery}&$count=true`;
            }

            // $filter
            if (pathOrqueryExpression.Filter) {
                odataQuery = `${odataQuery}&$filter=${pathOrqueryExpression.Filter}`;
            }

            // $expand
            if (pathOrqueryExpression.Expand && pathOrqueryExpression.Expand.length > 0) {
                odataQuery = `${odataQuery}&$expand=${pathOrqueryExpression.Expand.join(',')}`;
            }

            // $select
            if (pathOrqueryExpression.Select && pathOrqueryExpression.Select.length > 0) {
                odataQuery = `${odataQuery}&$select=${pathOrqueryExpression.Select.join(',')}`;
            }

            // $skip
            if (pathOrqueryExpression.Skip) {
                odataQuery = `${odataQuery}&$skip=${pathOrqueryExpression.Skip}`;
            }

            // $top
            if (pathOrqueryExpression.Top) {
                odataQuery = `${odataQuery}&$top=${pathOrqueryExpression.Top}`;
            }

            // // $count
            // if (pathOrqueryExpression.Count) {
            //     odataQuery = `${odataQuery}&$count=${pathOrqueryExpression.Count}`;
            // }

            // $orderby
            if (pathOrqueryExpression.OrderBy && pathOrqueryExpression.OrderBy.length > 0) {
                odataQuery = `${odataQuery}&$orderby=${pathOrqueryExpression.OrderBy.join(',')}`;
            }

            if (odataQuery.endsWith('?')) {
                odataQuery = odataQuery.replace('?', '');
            } else {
                odataQuery = odataQuery.replace('?&', '?');
            }
        }

        return odataQuery;
    }
    public static buidCustomDataQuery(pathOrqueryExpression: string | QueryExpression): string {
        let odataQuery = '';
        if (typeof pathOrqueryExpression === 'string') {
            odataQuery = pathOrqueryExpression;
        } else {
            if (pathOrqueryExpression.EntitySet) {
                odataQuery = `${pathOrqueryExpression.EntitySet}?`;
            } else {
                odataQuery = '?';
            }

            // $count
            if (pathOrqueryExpression.IsCount) {
                odataQuery = `${odataQuery}&$count=true`;
            }

            // $filter
            if (pathOrqueryExpression.Filter) {
                odataQuery = `${odataQuery}&ids=${pathOrqueryExpression.Filter}`;
            }

            // $expand
            // if (pathOrqueryExpression.Expand && pathOrqueryExpression.Expand.length > 0) {
            //     odataQuery = `${odataQuery}&$expand=${pathOrqueryExpression.Expand.join(',')}`;
            // }

            // $select
            // if (pathOrqueryExpression.Select && pathOrqueryExpression.Select.length > 0) {
            //     odataQuery = `${odataQuery}&$select=${pathOrqueryExpression.Select.join(',')}`;
            // }

            // $skip
            if (pathOrqueryExpression.Skip) {
                odataQuery = `${odataQuery}&skip=${pathOrqueryExpression.Skip}`;
            }

            // $top
            if (pathOrqueryExpression.Top) {
                odataQuery = `${odataQuery}&top=${pathOrqueryExpression.Top}`;
            }

            // // $count
            // if (pathOrqueryExpression.Count) {
            //     odataQuery = `${odataQuery}&$count=${pathOrqueryExpression.Count}`;
            // }

            // $orderby
            // if (pathOrqueryExpression.OrderBy && pathOrqueryExpression.OrderBy.length > 0) {
            //     odataQuery = `${odataQuery}&$orderby=${pathOrqueryExpression.OrderBy.join(',')}`;
            // }

            if (odataQuery.endsWith('?')) {
                odataQuery = odataQuery.replace('?', '');
            } else {
                odataQuery = odataQuery.replace('?&', '?');
            }
        }

        return odataQuery;
    }

    public static padLeft(data, size, paddingChar) {
        return (new Array(size + 1).join(paddingChar || '0') + String(data)).slice(-size);
    }

    public static pushFormToCell(cell: Cell, form: FormGroup): void {
        if (!cell.getColumn().editor.config) {
            cell.getColumn().editor.config = {};
        }
        cell.getColumn().editor.config.form = form;
    }

    public static validateNg2SmartTableRow(row: Row): boolean {
        let bValid = true;
        row.cells.forEach(cell => {
            if (cell.getColumn().editor
                && cell.getColumn().editor.config
                && cell.getColumn().editor.config.form) {
                const form: FormGroup = cell.getColumn().editor.config.form;
                this.validateAllFormFields(form);
                if (form.valid === false) {
                    bValid = false;
                }
            }
        });
        return bValid;
    }
    public static validateNg2SmartTableColumns(columns: Column[]): boolean {
        let bValid = true;
        columns.forEach(col => {
            if (col.editor
                && col.editor.config
                && col.editor.config.form) {
                const form: FormGroup = col.editor.config.form;
                this.validateAllFormFields(form);
                if (form.valid === false) {
                    bValid = false;
                }
            }
        });
        return bValid;
    }
    public static generateRandomPassword() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 8; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    public static toFormData<T>(formValue: T) {
        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }

        return formData;
    }

    public static hasError(formGroup: FormGroup, field: string, error: string): boolean {
        const control = formGroup.get(field);
        return control.touched && control.hasError(error);
    }

    public static initDateInputFilterTextMark(column: string): void {
        const tempDueDateInputFilter = setInterval(() => {
            const dueDateInputFilter = jQuery(`th[class*="${column}"]`).find('input-filter').find('input');
            if (dueDateInputFilter.length > 0) {
                clearInterval(tempDueDateInputFilter);
                for (let index = 0; index < dueDateInputFilter.length; index++) {
                    const element = dueDateInputFilter[index];
                    maskInput({
                        inputElement: element,
                        guide: true,
                        keepCharPositions: true,
                        showMask: true,
                        mask: Constants.Configure.INPUT_FILTER_MASK
                    });
                }
            }
        }, 1000);
    }

    public static convertToUTC(now: Date): Date {
        const nowUtc = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
        return nowUtc;
    }

    public static removeItemFromArray(item: any, array: any[]): void {
        let indexToRemove = -1;
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element === item) {
                indexToRemove = index;
            }
        }
        if (indexToRemove !== -1) {
            array.splice(indexToRemove, 1);
        }
    }

    public static buidQuery(pathOrqueryExpression: string | QueryExpression): string {
        let odataQuery = '';
        if (typeof pathOrqueryExpression === 'string') {
            odataQuery = pathOrqueryExpression;
        } else {
            if (pathOrqueryExpression.EntitySet) {
                odataQuery = `${pathOrqueryExpression.EntitySet}?`;
            } else {
                odataQuery = '?';
            }

            // $filter
            if (pathOrqueryExpression.Filter) {
                odataQuery = `${odataQuery}&${pathOrqueryExpression.Filter}`;
            }

            // offset
            if (pathOrqueryExpression.Skip) {
                odataQuery = `${odataQuery}&offset=${pathOrqueryExpression.Skip}`;
            }

            // limit
            if (pathOrqueryExpression.Top) {
                odataQuery = `${odataQuery}&limit=${pathOrqueryExpression.Top}`;
            }

            // $orderby
            if (pathOrqueryExpression.OrderBy && pathOrqueryExpression.OrderBy.length > 0) {
                odataQuery = `${odataQuery}&order_by=${pathOrqueryExpression.OrderBy.join(',')}`;
            }

            if (odataQuery.endsWith('?')) {
                odataQuery = odataQuery.replace('?', '');
            } else {
                odataQuery = odataQuery.replace('?&', '?');
            }
        }

        return odataQuery;
    }

    public static getDateTimePickerFormGroup(date): any {
        if (date && date !== '') {
            return date;
        } else {
            return null;
        }
    }

    public static getBooleanString(str: string): any {
        if (str === 'true') {
            return true;
        } else {
            return false;
        }
    }

    public static disableAllFroalaEditor(): any {
        setTimeout(() => {
            const $frView = $(".fr-box").find(".fr-view");
            const $button = $(".fr-box").find("button");
            $($frView).attr("contenteditable", false);
            $($button).attr("disabled", true).addClass("fr-disabled");
        }, 100);
    }

    public static filterByCustomDateRange(curFilters: any[], field, value): void {
        const minMax = value.split('@');
        if (minMax.length === 2) {
            const min = minMax[0];
            const max = minMax[1];
            if (min) {
                curFilters.push(`(${field} ge '${min}')`);
            }
            if (max) {
                curFilters.push(`(${field} le '${max}')`);
            }
        }
    }
    public static filterByCustomNumberRange(curFilters: any[], field, value): void {
        const minMax = value.split('-');
        if (minMax.length === 2) {
            const min = minMax[0];
            const max = minMax[1];
            if (min) {
                curFilters.push(`(${field} ge ${min})`);
            }
            if (max) {
                curFilters.push(`(${field} le ${max})`);
            }
        }
    }
    public static formatDateTime(dt: Date): string {
        if (dt) {
            const formattedValue = `${pad(dt.getDate())}/${pad(dt.getMonth() + 1)}/${pad(dt.getFullYear())} ${dt.getHours()}:${pad(dt.getMinutes())}`;
            return formattedValue;
        }
        return '';
    }
    public static greaterThanZero(value: any): boolean {
        if (this.isNullOrUndefined(value)) {
            return false;
        }
        if (Number(value) > 0) {
            return true;
        }
        return false;
    }
    public static formatNumber(value: number): string {
        if (!value) {
            value = 0;
        }
        if (Number(value) < 0.9) {
            return Number(value).toString();
        }
        const p = Number(value).toFixed(2).split('.');
        let data = p[0].split('').reverse().reduce(function (acc, num, i, orig) {
            return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
        }, '');
        if (value < 0) {
            return '-' + data;
        } else {
            return data;
        }
    }
    public static formatYear(value: number): string {
        if (!value) {
            value = 0;
        }
        return Number(value).toString();
    }
    public static convertDateToDatePicker(dt: Date) {
        if (dt) {
            return {
                year: dt.getFullYear(),
                month: dt.getMonth() + 1,
                day: dt.getDate()
            };
        } else {
            return null;
        }
    }
    public static getNumberToPost(value) {
        if (value || value == '0' || value == 0) {
            return Number(value);
        } else {
            return null;
        }
    }

    public static removeValidators(control: any) {
        control.setValue(null);
        control.setValidators(null);
        control.clearValidators();
        control.updateValueAndValidity();
    }

    public static keepActiveMenu(menuId) {
        sessionStorage.setItem('keepactivemenu', menuId.toString());
    }
    public static removeActiveMenu() {
        sessionStorage.removeItem('keepactivemenu');
    }
    public static getActiveMenuId(): number {
        var v = sessionStorage.getItem('keepactivemenu');
        if (v) {
            return Number(v);
        }
        return null;
    }
    public static setArrayToSessionStorage(key: string, valueArray: any[]) {
        const jsonConverter = new JsonConvert();
        const valueString = JSON.stringify(jsonConverter.serializeArray(valueArray))
        sessionStorage.setItem(key, valueString);
    }
    public static getArrayFromSessionStorage<T>(key: string, classReference: {
        new(): T;
    }): T[] {
        const jsonConverter = new JsonConvert();
        const valueArray: [] = JSON.parse(sessionStorage.getItem(key));
        return jsonConverter.deserializeArray(valueArray, classReference);
    }
    /**
  * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
  * images to fit into a certain area.
  *
  * @param {Number} srcWidth width of source image
  * @param {Number} srcHeight height of source image
  * @param {Number} maxWidth maximum available width
  * @param {Number} maxHeight maximum available height
  * @return {Object} { width, height }
  */
    public static calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return [srcWidth * ratio, srcHeight * ratio];
    }

    public static markAsPristine(formGroup: FormGroup, timeout = 500) {
        // make form dirty to disable save button
        setTimeout(() => {
            formGroup.markAsPristine();
        }, timeout);
    }
}
