import { IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { cleanSession } from 'selenium-webdriver/safari';
import { map } from 'rxjs/operators';

export namespace Constants {
    export class ColumnTitle {
        static readonly ACTIONS = 'Thao tác';
    }
    export class DateTime {
        static readonly DATE_FORMAT = 'dd/MM/yyyy';
        static readonly SECOND_DATE_FORMAT = 'yyyy-mm-dd';
        static readonly DATETIME_FORMAT = 'dd/MM/yyyy H:mm';
    }
    export class Money {
        static readonly VN = 'VNĐ';
        // static readonly USD = '$';
        // static readonly SAR = 'SAR';
    }
    export class Paging {
        static readonly PAGE_SIZE = 10;
        static readonly PAGE_SIZE_FISCAL_YEAR = 3;
        // maximum visible pages
        static readonly VISIBLE_PAGES: 5;
    }
    export class Configure {
        // tslint:disable-next-line:max-line-length
        static readonly INPUT_FILTER_MASK = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', 'đ', 'ế', 'n', ' ', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        static readonly SPECIAL_CHARACTERS_REGEX = /["~!#$%^&*\(\)+=`{}\[\]\|\\:;'<>\/?"]+/g;
    }
    export class Tooltip {
        static readonly CREATE = 'Tạo mới';
        static readonly EDIT = 'Sửa';
        static readonly DELETE = 'Xóa';
        static readonly OK = 'Đồng ý';
        static readonly CANCEL = 'Hủy';
        static readonly COMMENT = 'Phản hồi';
        static readonly VIEW = 'Xem chi tiết';
        static readonly REFRESH_STATUS = 'Cập nhật trạng thái';
    }

    export class CommonStatusFilter {
        static readonly ACTIVE = {
            value: '1',
            title: 'Công bố'
        };
        static readonly INACTIVE = {
            value: '0',
            title: 'Nháp'
        };
    }

    export class CommonActiveFilter {
        static readonly ACTIVE = {
            value: true,
            title: 'Công bố'
        };
        static readonly INACTIVE = {
            value: false,
            title: 'Nháp'
        };
    }

    export const AttachmentType = new Map([
        [1, 'File PDF'],
        [2, 'Audio nghe'],
        [3, 'Video'],
        [4, 'Khoa học miễn phí'],
        [5, 'File E-Books'],
        [6, 'Đề thi miễn phí'],
    ]);

    export class UserType {
        static readonly Member = 0;
        static readonly AdminPartner = 1;
        static readonly MCBookUser = 2;
        static readonly Seller = 4;
    }

    export class FileUpload {
        static readonly MAX_FILE_SIZE = 5; // MB
    }

    export class BlogType {
        static readonly Post = 1;
        static readonly Page = 2;
    }

    export class ActionGroup {
        static readonly CREATE = [
            {
                name: 'create',
                title: `<i class="fa fa-plus text-primary font1rem pointer" title="${Constants.Tooltip.CREATE}"></i>`,
            }
        ];
        static readonly VIEW = [
            {
                name: 'detail',
                title: `<i class="fa fa-eye text-secondary font1rem pointer" title="${Constants.Tooltip.VIEW}"></i>`,
            }
        ];
        static readonly EDIT = [
            {
                name: 'edit',
                title: `<i class="fa fa-edit edit-tms font1rem pointer" title="${Constants.Tooltip.EDIT}"></i>`,
            }
        ];
        static readonly DELETE = [
            {
                name: 'delete',
                title: `<i class="fa fa-trash-o delete-tms font1rem pointer" title="${Constants.Tooltip.DELETE}"></i>`,
            }
        ];
        static readonly VIEW_DELETE = [
            {
                name: 'detail',
                title: `<i class="fa fa-eye text-secondary font1rem pointer" title="${Constants.Tooltip.VIEW}"></i>`,
            }, {
                name: 'delete',
                title: `<i class="fa fa-trash-o text-danger font1rem pointer" title="${Constants.Tooltip.DELETE}"></i>`,
            }
        ];
        static readonly EDIT_DELETE = [
            {
                name: 'edit',
                title: `<i class="fa fa-edit mr-3 edit-tms font1rem pointer" title="${Constants.Tooltip.EDIT}"></i>`,
            }, {
                name: 'delete',
                title: `<i class="fa fa-trash-o delete-tms font1rem pointer" title="${Constants.Tooltip.DELETE}"></i>`,
            }
        ];
    }

    export class LocalStorageKey {
        static readonly CURRENT_USER_LOCAL_STORAGE_KEY = 'CURRENT_USER_LOCAL_STORAGE_KEY_CARVIVU';
        static readonly CURRENT_ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'CURRENT_ACCESS_TOKEN_LOCAL_STORAGE_KEY_CARVIVU';
    }

    export const ProvValue = {
        All: 'All',
        AB: "AB",
        BC: "BC",
        SK: "SK",
        MN: "MN",
        ON: "ON",
        QC: "QC(BC)",
        US: "US",
        CC: "QC(CC)",
        NB: "NB",
        NS: "NS",
        PE: "PE",
        NF: "NF",
    }

    export const Provice = [
        { value: ProvValue.All, title: 'All', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: ProvValue.AB, title: 'AB', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: ProvValue.BC, title: 'BC', HTML: '<div class="text-center"><span class="badge badge-pill badge-primary badge-status">{0}</span></div>' },
        { value: ProvValue.SK, title: 'SK', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.MN, title: 'MN', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.ON, title: 'ON', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.QC, title: 'QC(BC)', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.US, title: 'US', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.CC, title: 'QC(CC)', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.NB, title: 'NB', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.NS, title: 'NS', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.PE, title: 'PE', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: ProvValue.NF, title: 'NF', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
    ];



    export const CountiesValue = {
        All: 'All',
        Acadia: 1,
        Athabasca: 2,
        Banff: 3,
        Barrhead: 4,
        Beaver: 5,
        BigLakes: 6,
        Bighorn: 7,
        BirchHills: 8,
        Bonnyville: 9,
        Brazeau: 10,
        Calgary: 11,
        Camrose: 12,
        Cardston: 13,
        ClearHills: 14,
        Clearwater: 15,
        ColdLakeWRNo: 16,
        CrowsnestPass: 17,
        Cypress: 18,
        Edmonton: 19,
        ElkIsland: 20,
        Fairview: 21,
        Flagstaff: 22,
        Foothills: 23,
        FortyMile: 24,
        GrandePrairie: 25,
        Greenview: 26,
        ID4: 27,
        Jasper: 28,
        MuncipalityofJasper: 29,
        Kananaskis: 30,
        Kneehill: 31,
        LacLaBiche: 32,
        LacSteAnne: 33,
        Lacombe: 34,
        Lamont: 35,
        Leduc: 36,
        LesserSlaveRiver: 37,
        Lethbridge: 38,
        Mackenzie: 39,
        Minburn: 40,
        MountainView: 41,
        Newell: 42,
        NorthernLights: 43,
        NorthernSunrise: 44,
        Opportunity: 45,
        Paintearth: 46,
        Parkland: 47,
        Peace: 48,
        PincherCreek: 49,
        Ponoka: 50,
        Provost: 51,
        Ranchland: 52,
        RedDeer: 53,
        RockyView: 54,
        SaddleHills: 55,
        SmokyLake: 56,
        SmokyRiver: 57,
        SpecialAreaNo2: 58,
        SpecialAreaNo3: 59,
        SpecialAreaNo4: 60,
        SpiritRiver: 61,
        StPaul: 62,
        Starland: 63,
        Stettler: 64,
        Strathcona: 65,
        Sturgeon: 66,
        Taber: 67,
        Thorhild: 68,
        TwoHills: 69,
        VermilionRiver: 70,
        Vulcan: 71,
        Wainwright: 72,
        Warner: 73,
        Westlock: 74,
        Wetaskiwin: 75,
        Wheatland: 76,
        WillmoreWilderness: 77,
        WillowCreek: 78,
        WoodBuffaloPark: 80,
        WoodBuffalo: 81,
        Woodlands: 82,
        Yellowhead: 83,
        Drumheller: 84,
    }

    export const Counties = [
        { value: CountiesValue.All, title: 'All', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: CountiesValue.Acadia, title: 'Acadia', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: CountiesValue.Athabasca, title: 'Athabasca', HTML: '<div class="text-center"><span class="badge badge-pill badge-primary badge-status">{0}</span></div>' },
        { value: CountiesValue.Banff, title: 'Banff', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Barrhead, title: 'Barrhead', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Beaver, title: 'Beaver', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.BigLakes, title: 'Big Lakes', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Bighorn, title: 'Bighorn', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.BirchHills, title: 'Birch Hills', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Bonnyville, title: 'Bonnyville', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Brazeau, title: 'Brazeau', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Calgary, title: 'Calgary', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Camrose, title: 'Camrose', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Cardston, title: 'Cardston', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.ClearHills, title: 'Clear Hills', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Clearwater, title: 'Bighorn', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.ColdLakeWRNo, title: 'Cold Lake WR No 349', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.CrowsnestPass, title: 'Crowsnest Pass', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Cypress, title: 'Cypress', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Edmonton, title: 'Edmonton', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.ElkIsland, title: 'Elk Island', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Fairview, title: 'Fairview', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Flagstaff, title: 'Flagstaff', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Foothills, title: 'Foothills', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.FortyMile, title: 'Forty Mile', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.GrandePrairie, title: 'Grande Prairie', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Greenview, title: 'Greenview', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.ID4, title: 'ID 4', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Jasper, title: 'Jasper', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.MuncipalityofJasper, title: 'Muncipality of Jasper', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Kananaskis, title: 'Kananaskis', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Kneehill, title: 'Kneehill', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.LacLaBiche, title: 'Lac La Biche', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.LacSteAnne, title: 'Lac Ste. Anne', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Lacombe, title: 'Lacombe', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Lamont, title: 'Lamont', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Leduc, title: 'Leduc', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.LesserSlaveRiver, title: 'Lesser Slave River', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Lethbridge, title: 'Lethbridge', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Mackenzie, title: 'Mackenzie', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Minburn, title: 'Minburn', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.MountainView, title: 'Mountain View', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Newell, title: 'Newell', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.NorthernLights, title: 'Northern Lights', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.NorthernSunrise, title: 'Northern Sunrise', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Opportunity, title: 'Opportunity', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Paintearth, title: 'Paintearth', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Parkland, title: 'Parkland', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Peace, title: 'Peace', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.PincherCreek, title: 'Pincher Creek', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Ponoka, title: 'Ponoka', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Provost, title: 'Provost', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Ranchland, title: 'Ranchland', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.RedDeer, title: 'Red Deer', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.RockyView, title: 'Rocky View', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SaddleHills, title: 'Saddle Hills', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SmokyLake, title: 'Smoky Lake', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SmokyRiver, title: 'Smoky River', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SpecialAreaNo2, title: 'Special Area No. 2', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SpecialAreaNo3, title: 'Special Area No. 3', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SpecialAreaNo4, title: 'Special Area No. 4', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.SpiritRiver, title: 'Spirit River', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.StPaul, title: 'St. Paul', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Starland, title: 'Starland', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Stettler, title: 'Stettler', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Strathcona, title: 'Strathcona', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Taber, title: 'Taber', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Thorhild, title: 'Thorhild', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.TwoHills, title: 'Two Hills', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.VermilionRiver, title: 'Stettler', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Vulcan, title: 'Vulcan', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Wainwright, title: 'Wainwright', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Warner, title: 'Warner', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Westlock, title: 'Westlock', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Wetaskiwin, title: 'Wetaskiwin', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Wheatland, title: 'Wheatland', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.WillmoreWilderness, title: 'Willmore Wilderness', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.WillowCreek, title: 'Willow Creek', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.WoodBuffaloPark, title: 'Wood Buffalo Park', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.WoodBuffalo, title: 'Wood Buffalo', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Woodlands, title: 'Woodlands', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Yellowhead, title: 'Yellowhead', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
        { value: CountiesValue.Drumheller, title: 'Drumheller', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },

    ];

    // export const TblRefEmailStatus = {
    //     SEDAN: 1,
    //     SUV: 2,
    //     PICK_UP_TRUCK: 3,
    //     CUV: 4,
    //     MPV: 5,
    //     HATCHBACK: 6,
    //     TRUCK: 7,
    //     VAN_MINIVAN: 8,
    //     CITY_CAR: 9,
    //     SPORT_CAR: 10,
    //     COUPE: 11,
    //     CONVERTIBLE: 12,
    //     WAGON: 13,
    //     SPECIAL_PURPOSE: 14,
    // }

    export const TblRefEmailStatus = {
        All: 'All',
        RegularMail: 1,
        eMail: 2,
        Un_Subscribed: 4,
        RegularAndeMail: 5,
        Un_Assigned: 6,
        N_A: 7,
        Regular_Un_Subscribed : 8 
    }

    export const RefEmailStatus = [
        { value: TblRefEmailStatus.All, title: 'All' },
        { value: TblRefEmailStatus.RegularMail, title: 'Regular Mail' },
        { value: TblRefEmailStatus.eMail, title: 'eMail' },
        { value: TblRefEmailStatus.Un_Subscribed, title: 'Un-Subscribed' },
        { value: TblRefEmailStatus.RegularAndeMail, title: 'Regular & eMail' },
        { value: TblRefEmailStatus.Un_Assigned, title: 'Un-Assigned' },
        { value: TblRefEmailStatus.N_A, title: 'N/A' },
        { value: TblRefEmailStatus.Regular_Un_Subscribed, title: 'Regular & Un-Subscribed' },
    ];

    // 0 -  not choose
    // 1 -  You have not had any accidents
    // 2 -  Yes, you have had minor accidents
    // 3 - Yes, you have had moderate accidents
    export const TblRefStatusCodesValue = {
        All : 'All',
        Active: "A",
        Closed: "C",
        Deleted: "D",
        Review: "V",
        Inactive: "I"
    }

    export const TblRefStatusCodes = [
        { value: TblRefStatusCodesValue.All, title: 'All',  row : TblRefStatusCodesValue.All },
        { value: TblRefStatusCodesValue.Active, title: 'Active',  row : TblRefStatusCodesValue.Active },
        { value: TblRefStatusCodesValue.Closed, title: 'Closed' , row : TblRefStatusCodesValue.Closed},
        { value: TblRefStatusCodesValue.Deleted, title: 'Deleted' , row : TblRefStatusCodesValue.Deleted },
        { value: TblRefStatusCodesValue.Review, title: 'Review' , row : TblRefStatusCodesValue.Review},
        { value: TblRefStatusCodesValue.Inactive, title: 'In-active' , row : TblRefStatusCodesValue.Inactive},
    ];

    export const StatusCodesValue = {
        // All: "All",
        Active: "A",
        Inactive: "I"
    }
    export const StatusCodes = [
        // { value: StatusCodesValue.All, title: 'All', row : StatusCodesValue.Active},
        { value: StatusCodesValue.Active, title: 'Active', row : StatusCodesValue.Active},
        { value: StatusCodesValue.Inactive, title: 'In-active' , row : StatusCodesValue.Inactive},
    ];
    // 1 - Exceptional and almost new 
    // 2 - Excellent
    // 3 - Acceptable 
    export const ZoneValue = {
        All: "All",
        CT: "CT",
        NE: "NE",
        NW: "NW",
        SE: "SE",
        SW: "SW",
    }

    export const Zones = [
        { value: ZoneValue.All, title: 'All' },
        { value: ZoneValue.CT, title: 'CT' },
        { value: ZoneValue.NE, title: 'NE' },
        { value: ZoneValue.NW, title: 'NW' },
        { value: ZoneValue.SE, title: 'SE' },
        { value: ZoneValue.SW, title: 'SW' },
    ];

    // 1 - New
    // 2 - In - Progress
    // 3 - Close
    export const NoteCategories = {
        NotinUse: "%23 Not in Use",
        Incentive: "2021 Incentive",
        ConfirmedInfo: "Confirmed Info",
        County_Discrepancy: "County Discrepancy",
        County_Etc_Confirmed:  "County, Etc, Confirmed",
        Deceased: "Deceased",
        Dup: "Dup",
        Dup_OK: "Dup OK",
        Election_2020: "Election 2020",
        Grass_Roots_Survey: "Grass Roots Survey",
        Info_confirmed_May_2020_email: "Info confirmed May 2020 email",
        Katelyn_Media_List: "Katelyn Media List",
        Left_Message: "Left Message",
        Mail_Chimp_Active: "Mail Chimp Active",
        Marketing_Imported: "Marketing Imported",
        No: "No %23",
        No_411: "No 411",
        No_Ans_Mach: "No Ans Mach",
        no_pcl: "no pcl",
        Phoned_to_Confirm_Info: "Phoned to Confirm Info",
        PostGrid_Mail_Correction: "PostGrid Mail Correction",
        PostGrid_Mail_Varified_but_Returned: "PostGrid Mail Varified but Returned",
        Retired: "Retired",
        Return_Mail: "Return Mail",
        Returned_Mail: "Returned Mail",
        send_PCL: "send PCL",
        Send_PLC: "Send PLC",
        Set_To: "Set To",
        Set_To_Active: "Set To Active",
        Set_To_ALL: "Set To ALL",
        Set_To_Closed: "Set To Closed",
        Set_To_Deceased: "Set To Deceased",
        Set_To_Deleted: "Set To Deleted",
        Set_To_No_411: "Set To No 411",
        Set_To_Review: "Set To Review",
        Town_Hall_Attendee: "Town Hall Attendee",
        Updated: "Updated",
    }

    export const TblRefNoteCategories = [
        { value: NoteCategories.NotinUse, title: '# Not in Use', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: NoteCategories.Incentive, title: '2021 Incentive', HTML: '<div class="text-center"><span class="badge badge-pill badge-primary badge-status">{0}</span></div>' },
        { value: NoteCategories.ConfirmedInfo, title: 'Confirmed Info', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.County_Discrepancy, title: 'County Discrepancy', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.County_Etc_Confirmed, title: 'County, Etc, Confirmed', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Deceased, title: 'Deceased', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Dup, title: 'Dup', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Dup_OK, title: 'Dup OK', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Election_2020, title: 'Election 2020', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Grass_Roots_Survey, title: 'Grass Roots Survey', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Info_confirmed_May_2020_email, title: 'Info confirmed May 2020 email', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Katelyn_Media_List, title: 'Katelyn Media List', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Left_Message, title: 'Left Message', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Mail_Chimp_Active, title: 'Mail Chimp Active', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Marketing_Imported, title: 'Marketing Imported', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.No, title: 'No #', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.No_411, title: 'No 411', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.No_Ans_Mach, title: 'No Ans Mach', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.no_pcl, title: 'no pcl', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Phoned_to_Confirm_Info, title: 'Phoned to Confirm Info', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.PostGrid_Mail_Correction, title: 'PostGrid Mail Correction', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.PostGrid_Mail_Varified_but_Returned, title: 'PostGrid Mail Varified but Returned', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Retired, title: 'Retired', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Return_Mail, title: 'Return Mail', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.send_PCL, title: 'send PCL', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Send_PLC, title: 'Send PLC', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To, title: 'Set To', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_Active, title: 'Set To Active', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_ALL, title: 'Set To ALL', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_Closed, title: 'Set To Closed', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_Deceased, title: 'Set To Deceased', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_Deleted, title: 'Set To Deleted', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_No_411, title: 'Set To No 411', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Set_To_Review, title: 'Set To Review', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Town_Hall_Attendee, title: 'Town Hall Attendee', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
        { value: NoteCategories.Updated, title: 'Updated', HTML: '<div class="text-center"><span class="badge badge-pill badge-success badge-status">{0}</span></div>' },
    ];

    export const TblWorkers = [
        { value: 1, title: 'laurap' },
        { value: 3, title: 'Rosanne' },
        { value: 4, title: 'AccountingAssistant' },
        { value: 6, title: 'Help' },
        { value: 7, title: 'Account' },
        { value: 8, title: 'Reception' },
        { value: 9, title: 'Marketing' },
        { value: 10, title: 'steve' },
        { value: 16, title: 'Kathy' },
        // { value: 10, title: 'Trạng thái' },
        { value: 17, title: 'TrishL' },
        { value: 18, title: 'Tripts' },
        { value: 19, title: 'PaolinaN' },
    ];

    // 1 - Tin ban xe
    // 2 - Xe ban nhanh cho CarVivu
    export const SellingMethodValue = {
        NEW: 1,
        SELL_TO_CARVIVU: 2,
    }

    //0 - đang đăng kí
    // 1 - user hoạt động
    // 2 - user bị khoá
    export const MemberIsActiveValue = {
        Registering: 0,
        Enable: 1,
        Disable: 2
    }

    export const MemberIsActive = [
        { value: MemberIsActiveValue.Registering, title: 'Đang đăng kí', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: MemberIsActiveValue.Enable, title: 'Đang hoạt động', HTML: '<div class="text-center"><span class="badge badge-pill badge-primary badge-status">{0}</span></div>' },
        { value: MemberIsActiveValue.Disable, title: 'Ngưng hoạt động', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
    ];

    //0 - đang đăng kí - chờ duyệt
    // 1 - user hoạt động
    // 2 - user bị khoá
    export const ShowroomIsActiveValue = {
        Registering: 0,
        Enable: 1,
        Disable: 2
    }

    export const ShowroomIsActive = [
        { value: ShowroomIsActiveValue.Registering, title: 'Chờ duyệt', HTML: '<div class="text-center"><span class="badge badge-pill badge-warning badge-status">{0}</span></div>' },
        { value: ShowroomIsActiveValue.Enable, title: 'Đã duyệt', HTML: '<div class="text-center"><span class="badge badge-pill badge-primary badge-status">{0}</span></div>' },
        { value: ShowroomIsActiveValue.Disable, title: 'Ngưng hoạt động', HTML: '<div class="text-center"><span class="badge badge-pill badge-danger badge-status">{0}</span></div>' },
    ];

    export const FileType = [
        { value: 1, title: 'Banner trang chủ' },
        { value: 2, title: 'Banner trang mua xe' },
        { value: 3, title: 'Banner trang chi tiết xe' },
        { value: 4, title: 'Banner trang tin tức' },
    ];

    // 0 -  not choose
    // 1 - Gọi điện
    // 2 - Gọi video
    // 3 - Hẹn gặp
    // 4 - Gửi email
    // 5 - Chat
    export const ActivityTypeValue = {
        PHONE_CALL: 1,
        VIDEO_CALL: 2,
        APPOINTMENT: 3,
        EMAIL: 4,
        CHAT: 5,
    }

    export const ActivityType = [
        { value: ActivityTypeValue.PHONE_CALL, title: 'Gọi điện' },
        { value: ActivityTypeValue.VIDEO_CALL, title: 'Gọi video' },
        { value: ActivityTypeValue.APPOINTMENT, title: 'Hẹn gặp' },
        { value: ActivityTypeValue.EMAIL, title: 'Gửi email' },
        { value: ActivityTypeValue.CHAT, title: 'Chat' },
    ];

    // 0 - người dùng free
    // 1 - người dùng trả phí
    // 2 - VIP 
    export const CustomerTypeValue = {
        FREE: 0,
        PAID: 1,
        VIP: 2,
    }

    export const CustomerType = [
        { value: CustomerTypeValue.FREE, title: 'Người dùng miễn phí' },
        { value: CustomerTypeValue.PAID, title: 'Người dùng trả phí' },
        { value: CustomerTypeValue.VIP, title: 'Người dùng VIP' },
    ];

    // 1 - người dùng trả phí
    // 2 - VIP 
    export const ContactUsStatusValue = {
        WAIT_FOR_REPLY: 1,
        CONTACTED: 2,
    }

    export const ContactUsStatus = [
        { value: ContactUsStatusValue.WAIT_FOR_REPLY, title: 'Chưa liên hệ' },
        { value: ContactUsStatusValue.CONTACTED, title: 'Đã liên hệ' },
    ];

    export const CreatedByAdmin = [
        { value: true, title: 'Được tạo bởi admin' },
        { value: false, title: 'Người dùng đăng ký' },
    ];
    // 0 - xe mới và xe cũ
    // 1 - xe mới 
    // 2 - xe cũ 
    export const TypeOfShowroomValue = {
        NEW_AND_OLD_CAR: 0,
        NEW_CAR: 1,
        OLD_CAR: 2,
    }
    export const TypeOfShowroom = [
        { value: TypeOfShowroomValue.NEW_AND_OLD_CAR, title: 'Đại lý xe mới và cũ' },
        { value: TypeOfShowroomValue.NEW_CAR, title: 'Đại lý xe mới' },
        { value: TypeOfShowroomValue.OLD_CAR, title: 'Đại lý xe cũ' },
    ];

    // 0 - khách hàng không đăng ky
    // 1 - Đăng ký
    // 2 - Đang xác thực
    // 3 - Chờ phê duyệt
    // 4 - TỪ Chối
    // 5 - đăng ký thành công 
    export const CarMallStatusValue = {
        NO_REGISTER: 0,
        REGISTER: 1,
        WAITING_FOR_AUTHEN: 2,
        WAITING_FOR_APPROVAL: 3,
        REJECT: 4,
        REGISTERED_SUCCESS: 5,
    }
    export const CarMallStatus = [
        { value: CarMallStatusValue.NO_REGISTER, title: 'Không đăng ký' },
        { value: CarMallStatusValue.REGISTER, title: 'Đăng ký' },
        { value: CarMallStatusValue.WAITING_FOR_AUTHEN, title: 'Đang xác thực' },
        { value: CarMallStatusValue.WAITING_FOR_APPROVAL, title: 'Chờ phê duyệt' },
        { value: CarMallStatusValue.REJECT, title: 'Từ chối' },
        { value: CarMallStatusValue.REGISTERED_SUCCESS, title: 'Đăng ký thành công' },
    ];
}

