import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger,  state,  style, transition, animate } from '@angular/animations';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { UserLogged } from 'src/app/core/helpers/userlogged';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ],
  animations: [
    trigger('showInfo', [
      state('1' , style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public showHorizontalMenu:boolean = true; 
  public showInfoContent:boolean = false;
  public settings: Settings;
  public menuItems:Array<any>;
  public router: Router;
  username: string;
  userId: number;
  userData: Observable<firebase.User>;
  private route: ActivatedRoute;
  constructor(public appSettings:AppSettings, public menuService:MenuService,
    router: Router , route: ActivatedRoute , private angularFireAuth: AngularFireAuth
    ) {
      this.userData = angularFireAuth.authState;
      this.settings = this.appSettings.settings;
      this.menuItems = this.menuService.getHorizontalMenuItems();
      this.router = router;
  }
  
  ngOnInit() {
    if(window.innerWidth <= 768)  {
      this.showHorizontalMenu = false;
    }
      let userLogged: UserLogged = new UserLogged();
      if (userLogged.isLogged()) {
        // this.userLogin = true;
        let user = userLogged.getCurrentUser();
        this.userId = user.userId;
        this.username = user.userName;
      }
  }


  public closeSubMenus(){
    let menu = document.querySelector("#menu0"); 
    if(menu){
      for (let i = 0; i < menu.children.length; i++) {
          let child = menu.children[i].children[1];
          if(child){          
              if(child.classList.contains('show')){            
                child.classList.remove('show');
                menu.children[i].children[0].classList.add('collapsed'); 
              }             
          }
      }
    }
  }

  @HostListener('window:resize')
  public onWindowResize():void {
     if(window.innerWidth <= 768){
        this.showHorizontalMenu = false;
     }      
      else{
        this.showHorizontalMenu = true;
      }
  }
  logOut() {
    let userLogged: UserLogged = new UserLogged();
    userLogged.logout();
    this.username = '';
  //   this.angularFireAuth
  //   .auth
  //   .signOut();
  //   if (!firebase.apps.length) {
  //     firebase.initializeApp({});
  //  }else {
  //     firebase.app(); // if already initialized, use that one
  //  }
    this.router.navigate(['/login']);
    return false;
  }

}
