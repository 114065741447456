export const MessageContants = {
    CREATE_SUCESS: 'Thêm mới thành công.',
    UPDATE_SUCESS: 'Cập nhật thành công.',
    DELETE_SUCESS: 'Xóa thành công.',
    UNKNOWN_ERROR_OCCURRED: 'Có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại.',
    FORM_INVALID: 'Vui lòng kiểm tra lại dữ liệu.',
    NO_DATA_FOUND: 'Không có dữ liệu',
    DELETE_CONFIRM: 'Bạn có muốn xóa bản ghi này không?',
    REMOVE_MCCOIN_CONFIRM: 'Bạn có muốn hủy số MCCoin của người dùng này không?',
    REMOVE_SUCCESS: 'Hủy thành công',
    REMOVE_UNSUCCESS: 'Hủy không thành công',
    PLEASE_RELOAD: 'Vui lòng tải lại trang để lấy dữ liệu mới.',
    SELECT_ALL: 'Chọn tất cả',
    UN_SELECT_ALL: 'Bỏ chọn tất cả',
    SEARCH_PLACEHOLDER: 'Tìm...',
    ALL_SELECTED: 'Tất cả đã được chọn',
    NOTHING_FOUND: 'Không có kết quả...',
    CHECKED_PLURAL: 'được chọn',
    SELECT_CATEGORIES: 'Chọn danh mục thuốc',
    SELECT_AUTHORS: 'Chọn nhà sản xuất',
    SELECT_A_CATEGORIES: 'Chọn danh mục',
    SELECT_ATTACHMENTS: 'Chọn sản phẩm đi kèm',
    SELECT_PARENT_CATEGORIES: 'Chọn danh mục cha',
    SELECT_PARTNERS: 'Chọn gian hàng',
    TYPE: 'Select',
    CAN_NOT_DELETE_CATEGORY_BECAUSE_CONTAINS_CHILD_CATEGORIES: 'Bạn không thể xóa danh mục này vì nó có chứa danh mục con',
    CAN_NOT_DELETE_CATEGORY_BECAUSE_CONTAINS_CHILD_BOOKS: 'Bạn không thể xóa danh mục này vì nó có chứa sản phẩm thuốc liên quan',
    PLEASE_CHANGE_DATA: 'Bạn chưa sửa đổi dữ liệu',
    APPROVE_COMMENT: 'Bạn muốn duyệt bình luận này?',
    APPROVE_PARTNER: 'Bạn muốn duyệt gian hàng này?',
    CONFIRM_SALE_ALL_BOOKS: 'Bạn chắc chắn muốn bán tất cả sách?',
    CONFIRM_UN_SALE_ALL_BOOKS: 'Bạn chắc chắn muốn ngừng bán tất cả sách?',
    SUSPEND_PARTNER: 'Bạn chắc chắn muốn đóng gian hàng này?',
    CONFIRM_CATEGORY_FOR_PARTNER: 'Bạn có muốn hiển thị danh mục này lên gian hàng không?',
    CONFIRM_BOOK_FOR_PARTNER: 'Bạn có muốn hiển thị sách này lên gian hàng không?',
    CONFIRM_AUTHOR_FOR_PARTNER: 'Bạn có muốn hiển thị tác giả này lên gian hàng không?',
    LOGIN_FAILED: 'Đăng nhập không thành công'
};
