import {BaseService} from './base/base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonConvert} from 'json2typescript';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ModelHelper} from '../models/database/model-helper';
import { ODataResponse } from '../models/database/odata-response.model';
import { TblContact } from 'src/models/database/db.model';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class TblContactService {
  public jsonConvert: JsonConvert;
  constructor(private apiSrv: ApiService) {
    this.jsonConvert = new JsonConvert();
  }
  getContactById(id : number) : Observable<Object> {
    return this.apiSrv.get(`Contacts(${id})`).pipe(
      catchError(err => throwError(() => new Error(err))),
      );
  }
  getAllContact() : Observable<Object> {
    return this.apiSrv.get(`TblContacts?$count=true`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblContact"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  sendContact(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblContacts',formData);
  }
  getAllCountry() : Observable<Object> {
    return this.apiSrv.get(`Counties`).pipe(
      catchError(err => throwError(err)),
    );
  }
  getAllStatusContact() : Observable<Object> {
    return this.apiSrv.get(`TblRefStatusCodes`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefStatusCodes"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getAllTblRefEmailStatuses() : Observable<Object> {
    return this.apiSrv.get(`TblRefEmailStatuses`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefEmailStatuses"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getCountrybyId(id : number) : Observable<Object> {
    return this.apiSrv.get(`TblCounties?$filter=Id eq ${id}`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblCounties"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getProvince() : Observable<Object> {
    return this.apiSrv.get(`RefProvinces`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefProvinces"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getZonebyCountry(zone : string) : Observable<Object> {
    return this.apiSrv.get(`TblRefZones?$filter=ZoneCode eq '${zone}'`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefZones"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getCategoriesByContactId(Id : number) : Observable<Object> {
    return this.apiSrv.get(`TblContactCategories?$filter=ContactId eq ${Id}&$expand=TblRefCategory`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblContactCategories"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getNoteByContactId(Id : number) : Observable<Object> {
    return this.apiSrv.get(`TblContactNotes?$filter=ContactId eq ${Id}`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblContactNotes"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getRelatedContactbyId(Id : number) : Observable<Object> {
    return this.apiSrv.get(`TblContactRelateds?$filter=ContactId eq ${Id}&$count=true`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblContactRelateds"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getDuplicateContact(email : string, firstname: any , lastName :any) : Observable<Object> {
    return this.apiSrv.get(`TblContacts?$filter=Email eq '${email}' or FirstName eq '${firstname}' or LastName eq '${lastName}'&$count=true`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblContact"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  updateContact(body: any, ContactId: number): Observable<Object> {
    let url = `Contacts`;
    return this.apiSrv.patch(url, ContactId, body).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  updateNote(body: any, NoteId: number): Observable<Object> {
    let url = `TblContactNotes`;
    return this.apiSrv.patch(url, NoteId, body).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  deleteCategories(id: any): Observable<Object> {
    let url = `TblContactCategories`;
    return this.apiSrv.delete(url, id).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  AddNewNote(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblContactNotes',formData);
  }
  postCategories(formData : any) :  Observable<Object> {
    return this.apiSrv.post('PostListTblContactCategories',formData);
  }
  postChangelog(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblChangeLogs',formData);
  }
  updateNoteCategory(body: any, NoteCategoryId: number): Observable<Object> {
    let url = `TblRefNoteCategories`;
    return this.apiSrv.patch(url, NoteCategoryId, body).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  getNoteCategory() {
    return this.apiSrv.get(`TblRefNoteCategories?$filter=StatusCode eq 'A'`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefNoteCategories"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  getAllNoteCategory() {
    return this.apiSrv.get(`TblRefNoteCategories`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblRefNoteCategories"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
  postNoteCategory(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblRefNoteCategories',formData);
  }
  addRelatedContact(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblContactRelateds',formData);
  }
  // Login(username: string, password: string) :  Observable<Object> {
  //   let datapost = "userId=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password);
  //   return this.this.apiSrv.postLogin('Login',datapost);
  // }
  deleteNote(id: any): Observable<Object> {
    let url = `TblContactNotes`;
    return this.apiSrv.delete(url, id).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  getUser() {
    return this.apiSrv.get(`TblWorkers`).pipe(
      catchError(err => throwError(err)),
      // map((res) => this.jsonConvert.deserialize(res, ModelHelper.getClass('ServicePriceArray')))
      // map((res) => {
      //   const odateRes: ODataResponse = this.jsonConvert.deserializeObject(res, ODataResponse);
      //   let value = this.jsonConvert.deserializeArray(odateRes.value,  ModelHelper.getClass("TblWorkers"));
      //   odateRes.value = value;
      //   return odateRes;
      // })
    );
  }
}