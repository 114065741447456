import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    // path: '', 
    canActivate: [AuthGuard],
    component: PagesComponent,
    path: '', loadChildren: () => import('./pages/remittance/remittance.module').then(m => m.RemittanceModule)
  },
  // {path: 'remittance', loadChildren: () => import('./pages/remittance/remittance.module').then(m => m.RemittanceModule)},
  { path: 'login-remittance', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register',  loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)  },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  // preloadingStrategy: PreloadAllModules,  // <- uncomment this line for disable lazy load
  // useHash: true
});