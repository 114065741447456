import { JsonObject, JsonProperty, Any, ValueCheckingMode } from 'json2typescript';

@JsonObject('ODataResponse')
export class ODataResponse {
    @JsonProperty('@odata.context', String, true)
    context: String = undefined;

    @JsonProperty('@odata.etag', String, true)
    etag: String = undefined;

    @JsonProperty('@odata.nextLink', String, true)
    nextLink: String = undefined;

    @JsonProperty('@odata.count', Number, true)
    count: number = undefined;

    @JsonProperty('value', Any, true)
    value: any = undefined;
}
