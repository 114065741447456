import { JwtHelperService } from '@auth0/angular-jwt';
import { TblWorkerService } from 'src/services/tblWorker.service';
export class UserLogged {
  private readonly TOKENKEY: string = "Token";
  private readonly UserNameKey: string = "UserName";
  private readonly userIdKey: string = "userId";
  private readonly refresh_tokens: string = "refreshTokens";
  constructor() {
    // this.TOKENKEY = "Token";
    this.userIdKey = "UserId";
    this.UserNameKey = "UserName";
    // this.refresh_tokens = "refreshTokens";
  }
  getToken(): string {
    return this.getCookie(this.TOKENKEY);
  }
  getCurrentUser(): any {
    let userName = this.getCookie(this.UserNameKey);
    let userId = this.getCookie(this.userIdKey);
    // let token = this.getCookie(this.TOKENKEY);
    // let refresh_tokens = this.getCookie(this.refresh_tokens);
    return {
      userName: userName,
      userId: userId,
      // refresh_tokens: refresh_tokens,
      // token : token,
    };
  }
  setCurrentUser(token: string, userName: string, userId: string, refresh_tokens: string): void {
    // this.setCookie(this.TOKENKEY, token);
    this.setCookie(this.UserNameKey, userName);
    this.setCookie(this.userIdKey, userId);
    // this.setCookie(this.refresh_tokens, refresh_tokens);
  }
  // saveToken(token: string) {
  //   this.setCookie(this.TOKENKEY, token); 
  // }
  isLogged(): boolean {
    let userIdKey = this.getCookie(this.userIdKey);
    const helper = new JwtHelperService();
    if(userIdKey === "" || userIdKey === null)
      return false;
    else
      return true;
  }
  logout(): void {
    // this.deleteCookie(this.TOKENKEY);
    this.deleteCookie(this.UserNameKey);
    this.deleteCookie(this.userIdKey);
    // this.deleteCookie(this.refresh_tokens);
  }
  private deleteCookie(key): void {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  private setCookie(key: string, value: string): void {
    document.cookie = key + "=" + value + ";path=/";
  }
  private getCookie(cname) : string {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
