import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterEvent, RouterStateSnapshot } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { UserLogged } from '../helpers/userlogged';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {
    private previousRoute: string;  // Previous route to test if coming directly to this route or from another route
    private readonly navSubscription: Subscription;

    constructor(private router: Router) {
        // Listen for NavigationStart event which will indicate that the user is trying to navigate from another page
        this.navSubscription = this.router.events
            .subscribe((event: RouterEvent) => {
                if (event instanceof NavigationStart) {
                    this.previousRoute = this.router.url;
                }
            });
    }

    /**
     * Unsubscribe from subscriptions
     */
    public ngOnDestroy(): void {
        if (this.navSubscription) {
            this.navSubscription.unsubscribe();
        }
    }

    /**
     * Test if user is authenticated and if user's role is allowed access to the route
     * @param next
     * @param state
     */
    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        
        let userLogged: UserLogged = new UserLogged();
        if (userLogged.isLogged()) {
            return true;
        }
        // Not authenticated, send to login
        this.router.navigate(['/', 'login-remittance'], {queryParams: {return: state.url === '/login-remittance' ? undefined : state.url}});
        return false;
    }
}
