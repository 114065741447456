import { String } from 'typescript-string-operations';
import { saveAs } from 'file-saver';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { Constants } from '../constants/constants';

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
    return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isNgbDatepickerValue(model): boolean {
    if (isDefined(model)) {
        if (model.year && model.year >= 1700 && model.month && model.day) {
            return true;
        }
    }
    return false;
}

export function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export function setMinTimeForDate(dt) {
    const dtMinTime = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
    return dtMinTime;
}

export function setMaxTimeForDate(dt) {
    const dtMaxTime = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59);
    return dtMaxTime;
}

export function ngbDatepickerValueToDateTimeMin(value): string {
    const dt = new Date(value.year, value.month - 1, value.day);
    dt.setDate(dt.getDate() - 1);
    return String.Format('{0}-{1}-{2} 00:00:00', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()));
}

export function ngbDatepickerValueToDateTimeMax(value): string {
    const dt = new Date(value.year, value.month - 1, value.day);
    dt.setDate(dt.getDate() + 1);
    return String.Format('{0}-{1}-{2} 00:00:00', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()));
}

export function ngbDatepickerValueToDateTimeMinForCompareDateTime(value): string {
    if (isNgbDatepickerValue(value)) {
        const dt = new Date(value.year, value.month - 1, value.day);
        return String.Format('{0}-{1}-{2} 00:00:00', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()));
    } else {
        return null;
    }
}

export function ngbDatepickerValueToDateTimeMaxForCompareDateTime(value): string {
    if (isNgbDatepickerValue(value)) {
        const dt = new Date(value.year, value.month - 1, value.day);
        return String.Format('{0}-{1}-{2} 23:59:00', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()));
    } else {
        return null;
    }
}
export function ngbDatepickerValueToDateForCompareDateTime(value): string {
    if (isNgbDatepickerValue(value)) {
        const dt = new Date(value.year, value.month - 1, value.day);
        return String.Format('{0}-{1}-{2}', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()));
    } else {
        return null;
    }
}
// export function ngbDateTimepickerValueToDateTimeForCompareDateTime(dt: Date): string {
//     return String.Format('{0}-{1}-{2} {3}:{4}', dt.getFullYear(), pad(dt.getMonth() + 1), pad(dt.getDate()), pad(dt.getHours()), pad(dt.getMinutes()));
// }

export function ngbDatepickerValueToDateTimeMinTypeDateTime(value): Date {
    const dt = new Date(value.year, value.month - 1, value.day, 0, 0, 0);
    return dt;
}

export function ngbDatepickerValueToDateTimeMaxTypeDateTime(value): Date {
    const dt = new Date(value.year, value.month - 1, value.day, 23, 59, 0);
    return dt;
}

export function isDefined(value: any): boolean {
    return value !== undefined && value !== null;
}

export function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

export function regExpEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
    return element && element.className && element.className.split &&
        element.className.split(/\s+/).indexOf(className) >= 0;
}

export const MoneyMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    decimal: '.',
    precision: 0,
    prefix: '',
    suffix: ` ${Constants.Money.VN}`,
    // suffix: '',
    thousands: ','
};

export const YearMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    decimal: '',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: ''
};

export const IntMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    decimal: '.',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: ','
};

export const FloatMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    decimal: '.',
    precision: 1,
    prefix: '',
    suffix: '',
    thousands: ','
};

export const PercentMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: '',
    suffix: ' %',
    thousands: ','
};

/**
 * Saves a file by opening file-save-as dialog in the browser
 * using file-save library.
 * @param blobContent file content as a Blob
 * @param fileName name file should be saved as
 */
export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
};
